<template lang="html">
	<div class="container-fluid p-5">
		<loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header">
						<h6><a href="javascript:void(0)" class="text-info" @click="back()"> <i class="fas fa-arrow-left"></i></a> New city</h6>
					</div>
					<div class="card-body">
						<form @submit.prevent="onSubmit">
							<div class="form-group row">
								<label for="inputName" class="col-sm-2 col-form-label">Region <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<select class="form-control" v-model="cityRequest.region_id" @change="validationRule()">
										<option value="">Choose Region</option>
										<option v-for="region in regionList" :value="region.id">{{region.name}}</option>
									</select>
									<small class="text-danger">{{validationData.region_id}}</small>
								</div>
							</div>
							
							<div class="form-group row">
								<label for="inputName" class="col-sm-2 col-form-label">Name (Thai)<span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="text" v-model="cityRequest.name" @blur="validationRule()"
										class="form-control" id="inputName">
									<small class="text-danger">{{validationData.name}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputEngName" class="col-sm-2 col-form-label">Name (Eng) <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="text" v-model="cityRequest.en_name" @blur="validationRule()"
										class="form-control" id="inputEngName">
									<small class="text-danger">{{validationData.en_name}}</small>
								</div>
							</div>

							<button type="submit" class="btn btn-primary float-save">Submit</button>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="js">
	import {
		mapState,
		mapActions
	} from 'vuex'
	import env from '../../enviorments'
	import Loading from 'vue-loading-overlay'
	import 'vue-loading-overlay/dist/vue-loading.css';
	export default {
		name: 'cityCreate',
		metaInfo: {
            title: "city Create",
            titleTemplate: "%s ← SHRE Dashboard",
        },
		components: {
			Loading
		},
		data() {
			return {
				env,
				isLoading: false,
				fullPage: true,
				regionList: [],
				cityRequest: {
					region_id: "",
					name: "",
					en_name: ""
				},
				validationData: {
					region_id: "",
					name: "",
					en_name: ""
				}
			}
		},
		watch: {
			async $route(to, from) {
			},
		},
		computed: {},
		methods: {
			...mapActions({
				cityStoreAction: 'cityStoreAction',
				cityDetailAction: 'cityDetailAction'
			}),
			async getDetail(){
				let option = {
					type: "store"
				}
				await this.cityDetailAction({
					...option
				}).then(res => {
					this.regionList = res.data.region
					this.isLoading = false
				}).catch(err => this.isLoading = true)
			},
			async onSubmit() {
				this.isLoading = true
				let validation = this.validationRule()
				if (validation == true) {
					this.isLoading = false
					return
				}
				const formData = new FormData();
                formData.append('region_id', this.cityRequest.region_id);
				formData.append('name', this.cityRequest.name); 
				formData.append('en_name', this.cityRequest.en_name); 

			// 	for (const entry of formData.entries()) {
			// 		console.log(entry);
			// 	}

            //    console.log('FormData:', formData);

				await this.cityStoreAction(
					formData
				).then(res => {
					this.back()
					this.isLoading = false;
				}).catch(err => this.isLoading = true)
			},
			back() {
				this.$router.replace({ path: 'city-list', query: {page: 1, orderBy: 'desc', sortKey: 'created_at', name: ''} }).catch(()=>{})
			},
			validationRule() {
				let isValidation = []
				if (this.cityRequest.region_id == "") {
					this.validationData.region_id = "The region field is required."
					isValidation.push(true)
				} else {
					this.validationData.region_id = ""
					isValidation.push(false)
				}
				if (this.cityRequest.name == "") {
					this.validationData.name = "The name field is required."
					isValidation.push(true)
				} else {
					this.validationData.name = ""
					isValidation.push(false)
				}

				if (this.cityRequest.en_name == "") {
					this.validationData.en_name = "The name field is required."
					isValidation.push(true)
				} else {
					this.validationData.en_name = ""
					isValidation.push(false)
				}
				
				if (isValidation.includes(true)) {
					return true
				} else {
					return false
				}
			}
		},
		async mounted() {
			this.getDetail()
		}
	}
</script>

<style scoped lang="scss">
	.v-text-field--box .v-input__slot,
	.v-text-field--outline .v-input__slot {
		min-height: 56px;
	}
	.banner-image {
		width: 100px;
		height: 100px;
		object-fit: contain;
	}
</style>